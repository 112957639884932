<template>
	<div class="px-5 flex justify-center sm:justify-end xl:pr-20">
		<a
			:href="$props.src"
			class="
				mb-20 max-w-sm
				w-full text-brand-black
				r-button group
			"
		>
			<div
				class="
					relative flex items-center justify-between pb-2 opacity-75 easein-out group-hover:opacity-100
				"
			>
				<button
					v-text="$props.copy"
				/>

				<img
					class="absolute right-0 sm:mr-4"
					id="img"
					src="../assets/icons/arrow.svg"
				>
			</div>
			
			<div class="relative">
				<div id="border" class="absolute border-b border-black" />
				<div class="border-b border-black w-full opacity-25" />
			</div>
		</a>
	</div>
</template>

<script>
	export default {
		name: 'RButton',

		props: {
			copy: {
				type: String,
				default: null, 
			},

			src: {
				type: String,
				default: null,
			},
		},
	}
</script>

<style lang="scss" scoped>
	.r-button {
		#border {
			transition: all .3s cubic-bezier(.075, .82, .165, 1);
			width: 0;
		}

		#img {
			transition: all .5s cubic-bezier(.075, .82, .165, 1);
		}
	}

	.r-button:hover {
		#border {
			width: 100%;
		}

		#img {
			margin-right: 0;
		}
	}
</style>
