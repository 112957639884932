<template>
	<div>
		<div class="px-5 2xl:max-w-screen-2xl">
			<h1 class="r-h1">
				<span
					v-text="$props.content.title1"
				/>

				<span
					class="flex lg:justify-end lg:-mr-5 2xl:mr-0"
					v-text="$props.content.title2"
				/>
			</h1>
		</div>

		<div
			class="
				flex flex-col
				lg:justify-between lg:flex-row-reverse
			"
		>
			<img
				class="
					w-full max-w-screen-sm my-5
					lg:-mt-16
					xl:max-w-screen-md xl:-mt-20
				"
				:src="$props.content.image.src"
				:alt="$props.content.image.alt"
			>

			<p
				class="
					px-5 mt-5 r-h2 max-w-screen-md
					md:w-3/4
					lg:pr-20 lg:mt-40
					2xl:pl-20
				"
				v-text="$props.content.copy"
			/>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AboutHeader',

		props: {
			content: {
				type: Object,
				default: () => ({
					title1: 'Getting to ',
					title2: 'know me.',
					copy: 'I’ve always had a shared passion for design + development & have developed both areas with great clients & teams over the years.',
					image: {
						src: require('../assets/general/ryan-profile.jpeg'),
						alt: 'Picture of Ryan',
					},
				})
			},
		},
	}
</script>
