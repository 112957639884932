<template>
	<div>
		<ProjectHeader
			:title="$props.title"
			:subTitle="$props.subTitle"
			:deliverables="$props.deliverables"
		/>

		<RButton
			class="-mb-12"
			:copy="$props.cta.copy"
			:src="$props.cta.src"
		/>

		<ImageGrid
			:images="$props.imageGrid01"
		/>

		<ProjectCopy
			copy="The website"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull01.src"
			:alt="$props.imageFull01.alt"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull02.src"
			:alt="$props.imageFull02.alt"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull05.src"
			:alt="$props.imageFull05.alt"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull04.src"
			:alt="$props.imageFull04.alt"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull03.src"
			:alt="$props.imageFull03.alt"
		/>

		<ProjectCopy
			copy="P&P Merch + photography"
		/>

		<Carousel
			:images="$props.carousel"
		/>

		<Carousel
			:images="$props.carousel02"
		/>

		<ProjectCopy
			copy="More of the P&P brand"
		/>

		<ImageGrid
			:images="$props.imageGrid02"
		/>

		<Contact />
	</div>
</template>

<script>
	import ProjectHeader from '@/components/ProjectHeader.vue'
	import ImageFull from '@/components/ImageFull.vue'
	import ImageGrid from '@/components/ImageGrid.vue'
	import ProjectCopy from '@/components/ProjectCopy.vue'
	import Carousel from '@/components/Carousel.vue'
	import Contact from '@/components/Contact.vue'
	import RButton from '@/components/RButton.vue'

	export default {
		name: 'PoseAndPurpose',

		components: {
			ProjectHeader,
			ImageFull,
			ImageGrid,
			ProjectCopy,
			Carousel,
			Contact,
			RButton
		},

		props: {
			title: {
				type: String,
				default: 'Pose & Purpose',
			},

			subTitle: {
				type: String,
				default: 'A contemporary & elegant yoga studio in London.',
			},

			deliverables: {
				type: Array,
				default: () => ([
					'Web design + development',
					'Full-scale branding',
					'Design direction',
					'Social media content',
					'Photography',
				])
			},

			imageFull01: {
				type: Object,
				default: () => ({
					src: require('../assets/poseandpurpose/pandp01.jpg'),
					alt: 'Pose & Purpose Website',
				})
			},

			imageFull02: {
				type: Object,
				default: () => ({
					src: require('../assets/poseandpurpose/pandp02.jpg'),
					alt: 'Pose & Purpose Website',
				})
			},

			imageFull03: {
				type: Object,
				default: () => ({
					src: require('../assets/poseandpurpose/pandp03.jpg'),
					alt: 'Pose & Purpose Website',
				})
			},

			imageFull04: {
				type: Object,
				default: () => ({
					src: require('../assets/poseandpurpose/pandp04.jpg'),
					alt: 'Pose & Purpose Website',
				})
			},
			
			imageFull05: {
				type: Object,
				default: () => ({
					src: require('../assets/poseandpurpose/pandp05.jpg'),
					alt: 'Pose & Purpose Website',
				})
			},

			imageGrid01: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/poseandpurpose/logo.jpg'),
						alt: 'Pose & Purpose',
					},
					{
						src: require('../assets/poseandpurpose/logo-short.jpg'),
						alt: 'Pose & Purpose',
					},
				])
			},

			imageGrid02: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/poseandpurpose/welcome-post.jpg'),
						alt: 'Pose & Purpose',
					},
					{
						src: require('../assets/poseandpurpose/star.jpg'),
						alt: 'Pose & Purpose',
					},
					{
						src: require('../assets/poseandpurpose/website-live-01.jpg'),
						alt: 'Pose & Purpose Site',
					},
					{
						src: require('../assets/poseandpurpose/website-live-02.jpg'),
						alt: 'Pose & Purpose Site',
					},
					{
						src: require('../assets/poseandpurpose/pandp06.jpg'),
						alt: 'Pose & Purpose',
					},
					{
						src: require('../assets/poseandpurpose/website-live-03.jpg'),
						alt: 'Pose & Purpose Site',
					},
				])
			},

			carousel: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/poseandpurpose/bag.jpg'),
						alt: 'Pose & Purpose',
					},
					{
						src: require('../assets/poseandpurpose/bag-04.jpg'),
						alt: 'Pose & Purpose',
					},
					{
						src: require('../assets/poseandpurpose/bag-02.jpg'),
						alt: 'Pose & Purpose',
					},
					{
						src: require('../assets/poseandpurpose/bag-03.jpg'),
						alt: 'Pose & Purpose',
					},
				])
			},

			carousel02: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/poseandpurpose/collage-01.jpg'),
						alt: 'Pose & Purpose',
					},
					{
						src: require('../assets/poseandpurpose/collage-02.jpg'),
						alt: 'Pose & Purpose',
					},
					{
						src: require('../assets/poseandpurpose/new-blog-01.jpg'),
						alt: 'Pose & Purpose',
					},
					{
						src: require('../assets/poseandpurpose/new-blog-02.jpg'),
						alt: 'Pose & Purpose',
					},
				])
			},

			cta: {
				type: Object,
				default: () => ({
					copy: 'View website',
					src: 'https://www.poseandpurpose.com',
				}),
			},
		},

		mounted() {
			window.scrollTo(0, 0)
		},
	}
</script>
