<template>
	<div
		class="
			px-5
			2xl:px-20
		"
	>
		<img
			class="w-full"
			:src="$props.src"
			:alt="$props.alt"
		>
	</div>
</template>

<script>
	export default {
		name: 'ImageFull',

		props: {
			src: String,
			alt: String,
		},
	}
</script>
