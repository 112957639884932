<template>
	<div>
		<ProjectHeader
			:title="$props.title"
			:subTitle="$props.subTitle"
			:deliverables="$props.deliverables"
		/>

		<!-- Caña -->
		<ProjectCopy
			copy="Caña. <br> <span> Spanish Tapas Bar + Restaurant.</span> <br> <span>[v1 / 3]</span>"
		/>

		<Carousel
			:images="$props.canaCarouselV2"
		/>

		<ProjectCopy
			copy="Caña. <br> <span>[v2 / 3]</span>"
		/>

		<Carousel
			:images="$props.canaCarouselV1"
		/>

		<ProjectCopy
			copy="Caña. <br> <span>[v3 / 3]</span>"
		/>

		<Carousel
			:images="$props.canaCarouselV3"
		/>

		<div class="hr-black my-10 mx-5" />

		<!-- Code Zero -->
		<ProjectCopy
			copy="Code Zero. <br> <span>Football team.</span>"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFullCzBanner.src"
			:alt="$props.imageFullCzBanner.alt"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFullCzLogo.src"
			:alt="$props.imageFullCzLogo.alt"
		/>

		<div class="hr-black my-10 mx-5" />

		<!-- Verte -->
		<ProjectCopy
			copy="Verte. <br> <span>Cafe + Florist.</span>"
		/>

		<Carousel
			:images="$props.verteCarousel"
		/>

		<ImageFull
			class="-mt-5 md:-mt-12 mb-5 md:mb-10"
			:src="$props.imageFullVerte.src"
			:alt="$props.imageFullVerte.alt"
		/>

		<Contact />
	</div>
</template>

<script>
	import ProjectHeader from '@/components/ProjectHeader.vue'
	import ImageFull from '@/components/ImageFull.vue'
	import ProjectCopy from '@/components/ProjectCopy.vue'
	import Carousel from '@/components/Carousel.vue'
	import Contact from '@/components/Contact.vue'

	export default {
		name: 'Logofolio',

		components: {
			ProjectHeader,
			ImageFull,
			ProjectCopy,
			Carousel,
			Contact,
		},

		props: {
			title: {
				type: String,
				default: 'Logos + branding',
			},

			subTitle: {
				type: String,
				default: 'A collection of logos + branding for a range of businesses.',
			},

			deliverables: {
				type: Array,
				default: () => ([
					'Resturants',
					'Sports teams',
					'+ more',
				])
			},

			// Pastel

			// Caña V1
			canaCarouselV1: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/mini-brands/cana/v1/cana-cream-main.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v1/cana-cream-icons.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v1/cana-cream-circle.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v1/cana-blue-main.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v1/cana-blue-icons.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v1/cana-blue-circle.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v1/cana-pink-main.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v1/cana-pink-icons.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v1/cana-pink-circle.jpg'),
						alt: 'Caña Logo',
					},
				])
			},

			// Caña V2
			canaCarouselV2: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/mini-brands/cana/v2/cana-main-cream.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v2/cana-main-blue.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v2/cana-splat-01.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v2/cana-emblem-01.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v2/cana-splat-02.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v2/cana-emblem-02.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v2/cana-splat-04.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v2/cana-emblem-03.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v2/cana-main-blue-rotate.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v2/cana-main-cream-rotate.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v2/cana-coffee.jpg'),
						alt: 'Caña Coffee Cups',
					},
					{
						src: require('../assets/mini-brands/cana/v2/cana-cans.jpg'),
						alt: 'Caña Cans',
					},
					{
						src: require('../assets/mini-brands/cana/v2/cana-sign.jpg'),
						alt: 'Caña Sign',
					},
				])
			},

			// Caña V3
			canaCarouselV3: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/mini-brands/cana/v3/cana-sq-01.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v3/cana-sq-02.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v3/cana-sq-03.jpg'),
						alt: 'Caña Logo',
					},
					{
						src: require('../assets/mini-brands/cana/v3/cana-sq-04.jpg'),
						alt: 'Caña Logo',
					},
				])
			},

			// Code Zero
			imageFullCzLogo: {
				type: Object,
				default: () => ({
					src: require('../assets/mini-brands/codezero/code-zero-logo.jpg'),
					alt: 'Code Zero Logo',
				})
			},

			imageFullCzBanner: {
				type: Object,
				default: () => ({
					src: require('../assets/mini-brands/codezero/code-zero-banner.jpg'),
					alt: 'Code Zero Kits',
				})
			},

			// Verte
			verteCarousel: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/mini-brands/verte/verte-pink.png'),
						alt: 'Verte Logo',
					},
					{
						src: require('../assets/mini-brands/verte/verte-cream.png'),
						alt: 'Verte Logo',
					},
					{
						src: require('../assets/mini-brands/verte/verte-flowers.jpg'),
						alt: 'Verte Logo',
					},
					{
						src: require('../assets/mini-brands/verte/verte-flowers2.jpg'),
						alt: 'Verte Logo',
					},
				])
			},

			imageFullVerte: {
				type: Object,
				default: () => ({
					src: require('../assets/mini-brands/verte/verte-banner.jpg'),
					alt: 'Verte Typography',
				})
			},
		},

		mounted() {
			window.scrollTo(0, 0)
		},
	}
</script>
