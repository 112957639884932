<template>
	<div
		class="
			px-5
			2xl:px-20
		"
	>
		<h2
			class="
				r-h3 w-full max-w-screen-md mt-16 mb-5
				md:w-1/2 md:mt-24 md:mb-10
			"
			v-html="$props.copy"
		/>
	</div>
</template>

<script>
	export default {
		name: 'ProjectCopy',

		props: {
			copy: {
				type: String,
				required: true,
			},
		},
	}
</script>

<style lang="scss">
	h2 {
		span {
			margin-top: 10px;
			font-size: 20px;
			line-height: .5em;
		}
	}
</style>
