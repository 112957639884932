<template>
	<div class="noise bg-black text-brand-white py-10 text-center">
		<div
			class="
				flex flex-col items-center justify-center
				md:flex-row md:space-x-4 md:mb-4
			"
		>
			<div class="opacity-50 text-xs">
				<p
					v-if="$data.hasBumped"
					class="ping"
					v-text="$data.bumpedMessage"
				/>

				<p
					v-else
					v-text="$props.message"
				/>
			</div>

			<img
				@click="bumped()"
				:class="[
					'w-4 my-4 animate animate-pulse md:my-0 cursor-pointer',
					{
						'hidden': $data.hasBumped,
					}
				]"
				src="../assets/icons/fist.svg"
			>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Fun',

		props: {
			message: {
				type: String,
				default: 'Nice to see you down here. Fist bump?',
			},
		},

		data() {
			return {
				hasBumped: false,
				bumpedMessage: 'POW!!',
			}
		},

		methods: {
			bumped() {
				this.$data.hasBumped = true;
			}
		},
	}
</script>

<style lang="scss">
.ping {
	animation: ping 1s cubic-bezier(0, 0, 0.2, 1);

	@keyframes ping {
		0%, 100% {
			transform: scale(1);
		}

		50% {
			transform: scale(1.5);
		}
	}
}
</style>
