<template>
	<div
		class="
			pl-5 mb-10
			md:mb-20
			2xl:pl-20
		"
	>
		<div
			class="
				flex space-x-5 overflow-x-auto no-scrollbar
			"
		>
			<img
				class="w-5/6 md:w-4/6 lg:w-2/6"
				v-for="item in $props.images"
				:key="item"
				:src="item.src"
				:alt="item.alt"
			>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Carousel',

		props: {
			images: {
				type: Array,
				required: true,
			},
		},
	}
</script>

<style lang="scss">
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}
</style>
