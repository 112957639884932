<template>
	<div class="noise bg-black text-brand-white space-y-10 py-40">
		<h3
			class="r-h2 mx-auto text-center"
			v-html="$props.title"
		/>

		<p
			class="r-copy text-center mx-auto px-10 lh"
			v-text="$props.copy"
		/>

		<div
			class="
				flex flex-col justify-center text-center space-y-5
				md:flex-row md:space-x-16 md:space-y-0 
			"
		>
			<ul
				v-for="item in $props.social"
				:key="item"
			>
				<a
					:href="item.src"
				>
					<li
						class="link-hover--pink r-h2 text-brand-pink hover:opacity-50 duration-300 ease-in-out"
						v-text="item.title"
					/>
				</a>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Contact',

		props: {
			title: {
				type: String,
				default: 'It would be great<br>to hear from you.',
			},

			copy: {
				type: String,
				default: 'Can I help? Drop me a message! If not, still swing by!',
			},

			social: {
				type: Array,
				default: () => ([
					{
						title: 'LinkedIn',
						src: 'https://www.linkedin.com/in/ryan-gledhill-096763119/',
					},
					{
						title: 'Email',
						src: 'mailto:ryanjgledhill@gmail.com',
					},
					{
						title: 'The gram',
						src: 'https://www.instagram.com/ry.desgn/',
					},
				])
			},
		},
	}
</script>

<style lang="scss" scoped>
.lh {
	line-height: 1.5em;
}
</style>
