<template>
	<div
		:class="[
			'flex items-center space-x-5',
			$props.dark
				? 'text-brand-black'
				: 'text-brand-pink',
		]"
	>
		<img
			v-if="$props.dark"
			class="animate animate-pulse"
			src="../assets/icons/circle-dark.svg"
		>

		<img
			v-else
			class="animate animate-pulse"
			src="../assets/icons/circle-pink.svg"
		>

		<span
			v-text="$props.tag"
		/>
	</div>
</template>

<script>
	export default {
		name: 'Tag',

		props: {
			tag: {
				type: String,
				default: null,
			},

			dark: {
				type: String,
				default: null,
			},
		},
	}
</script>
