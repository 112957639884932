<template>
	<div class="noise bg-black text-brand-white pt-5">
		<TagText
			tag="Creative timeline."
			copy="Where i’ve been + what i’ve done."
		/>

		<div
			class="
				grid grid-cols-1 gap-10
				md:grid-cols-2
				px-5 py-10
				md:mr-10 md:ml-10
				2xl:px-20
			"
		>
			<div
				class="border-b mb-2"
				v-for="item in $data.items"
				:key="item"
			>
				<span
					class="text-sm"
					v-html="item.date"
				/>

				<h3
					class="mt-2 mb-4 text-lg"
					v-html="item.title"
				/>
			</div>
		</div>

		<Contact />
	</div>
</template>

<script>
	import TagText from '@/components/TagText.vue'
	import Contact from '@/components/Contact.vue'

	export default {
		name: 'Timeline',

		components: {
			TagText,
			Contact,
		},

		data() {
			return {
				items: [
					{
						title: 'Creative Media Technology @ Leeds Beckett University.',
						date: '2016 / 2020',
					},
					{
						title: 'Internship @ <a href="https://engageinteractive.co.uk/" target="_data">Engage Interactive</a>, Leeds.',
						date: '2018 / 2019',
					},
					{
						title: 'First official freelance project, <a href="/threes-a-crowd">Three’s A Crowd.</a>',
						date: '2018 / 2019',
					},
					{
						title: 'Junior Frontend Developer @ <a href="https://engageinteractive.co.uk/" target="_data">Engage Interactive</a>, Leeds.',
						date: '2019 / 2021',
					},
					{
						title: 'Established freelance design + development work.',
						date: '2020 / Ongoing',
					},
					{
						title: '<a href="/the-music-awaits">Featured project</a> in the Leeds Beckett End of Year Showcase.',
						date: '2020',
					},
					{
						title: 'Graduated university with a First Class degree.',
						date: '2021',
					},
					{
						title: 'Frontend Developer @ <a href="https://engageinteractive.co.uk/" target="_data">Engage Interactive</a>, Leeds.',
						date: '2021 / 2022',
					},
					{
						title: 'Javascript Developer @ <a href="https://neverbland.com/" target="_data">Neverbland</a>, London.',
						date: '2022 / Ongoing',
					},
					{
						title: 'What could be next?',
						date: '<i>Sometime in the future...</i>',
					},
				],
			}
		},
	}
</script>
