<template>
	<div
		:class="
			{
				'relative -mt-14 md:-mt-18 h-screen flex flex-col items-center justify-center' : $props.fullHeight
			}
		"
	>
		<div :class="cMarquee">
			<div class="marquee__inner">
				<span
					v-html="$props.marquee_left.first"
				/>
			</div>
		</div>

		<div class="absolute bottom-0 left-0 right-0 mb-5 md:mb-8">
			<div
				v-if="$props.marquee_right.right"
				:class="cMarqueeSm"
			>
				<div
					class="marquee__inner border-black border-t border-b py-5"
					:class="[
						{
							'anim-dir-rev': $props.marquee_right.right,
						}
					]"
				>
					<span
						v-html="$props.marquee_right.first"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Marquee',

		props: {
			marquee_left: {
				type: Object,
				default: () => ({})
			},

			marquee_right: {
				type: Object,
				default: () => ({})
			},

			fullHeight: {
				type: Boolean,
				default: null,
			},
		},

		computed: {
			cMarquee() {
				return 'marquee r-h1 h-24 md:h-32 lg:h-48 xl:h-60';
			},

			cMarqueeSm() {
				return 'marquee h-20 text-xl uppercase font-bold h-12';
			}
		},
	}
</script>

<style lang="scss">
	.marquee {
		position: relative;
		width: 100vw;
		max-width: 100%;
		overflow: hidden;

		&__inner {
			position: absolute;
			white-space: nowrap;
			will-change: transform;
			animation: marquee 100s linear infinite;

			span {
				i {
					color: #f9cdcd;
				}
			}
		}
	}

	@keyframes marquee {
		from { transform: translateX(0); }
		to { transform: translateX(-50%); }
	}

	.anim-dir-rev {
		animation-direction: reverse;
	}
</style>
