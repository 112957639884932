<template>
	<div>
		<ProjectHeader
			:title="$props.title"
			:subTitle="$props.subTitle"
			:deliverables="$props.deliverables"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull01.src"
			:alt="$props.imageFull01.alt"
		/>

		<ImageGrid
			:images="$props.imageGrid01"
		/>

		<ProjectCopy
			copy="Custom gradients."
		/>

		<Carousel
			:images="$props.carousel"
		/>

		<ImageGrid
			:images="$props.imageGrid02"
		/>

		<ProjectCopy
			copy="Exploring the S97 gradients."
		/>

		<ImageGrid
			class="mb-5"
			:images="$props.imageGrid03"
		/>

		<Contact />
	</div>
</template>

<script>
	import ProjectHeader from '@/components/ProjectHeader.vue'
	import ImageFull from '@/components/ImageFull.vue'
	import ImageGrid from '@/components/ImageGrid.vue'
	import ProjectCopy from '@/components/ProjectCopy.vue'
	import Carousel from '@/components/Carousel.vue'
	import Contact from '@/components/Contact.vue'

	export default {
		name: 'Studio97',

		components: {
			ProjectHeader,
			ImageFull,
			ImageGrid,
			ProjectCopy,
			Carousel,
			Contact,
		},

		props: {
			title: {
				type: String,
				default: 'Studio 97.',
			},

			subTitle: {
				type: String,
				default: 'Visual identity for a creative group.',
			},

			deliverables: {
				type: Array,
				default: () => ([
					'Logo development',
					'Visual identity',
					'Brand image',
				])
			},

			imageFull01: {
				type: Object,
				default: () => ({
					src: require('../assets/s97/s97-banner.jpg'),
					alt: 'Studio 97 logo',
				})
			},

			imageGrid01: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/s97/candy-main.jpg'),
						alt: 'Studio 97 logo',
					},
					{
						src: require('../assets/s97/just-black-main.jpg'),
						alt: 'Studio 97 logo',
					},
				])
			},

			imageGrid02: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/s97/midnight-repeat.jpg'),
						alt: 'Studio 97 logo',
					},
					{
						src: require('../assets/s97/midnight-icon.jpg'),
						alt: 'Studio 97 logo',
					},
					{
						src: require('../assets/s97/forrest-icon.jpg'),
						alt: 'Studio 97 logo',
					},
					{
						src: require('../assets/s97/candy-icon.jpg'),
						alt: 'Studio 97 logo',
					},
					{
						src: require('../assets/s97/sunburst-icon.jpg'),
						alt: 'Studio 97 logo',
					},
					{
						src: require('../assets/s97/sunburst-repeat.jpg'),
						alt: 'Studio 97 logo',
					},
				])
			},

			carousel: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/s97/sunburst.jpg'),
						alt: 'Studio 97 - Sunburst',
					},
					{
						src: require('../assets/s97/forrest.jpg'),
						alt: 'Studio 97 - Forrest',
					},
					{
						src: require('../assets/s97/candy.jpg'),
						alt: 'Studio 97 - Candy',
					},
					{
						src: require('../assets/s97/mushroom.jpg'),
						alt: 'Studio 97 - Mushroom',
					},
					{
						src: require('../assets/s97/midnight.jpg'),
						alt: 'Studio 97 - Midnight',
					},
					{
						src: require('../assets/s97/just-black.jpg'),
						alt: 'Studio 97 - Just Black',
					},
				])
			},

			imageGrid03: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/s97/cards/forrest.jpg'),
						alt: 'Studio 97 - Forrest Card',
					},
					{
						src: require('../assets/s97/cards/just-black.jpg'),
						alt: 'Studio 97 - Just Black Card',
					},
					{
						src: require('../assets/s97/cards/sunburst.jpg'),
						alt: 'Studio 97 - Sunburst Card',
					},
					{
						src: require('../assets/s97/cards/midnight.jpg'),
						alt: 'Studio 97 - Midnight Card',
					},
					{
						src: require('../assets/s97/cards/mushroom.jpg'),
						alt: 'Studio 97 - Mushroom Card',
					},
					{
						src: require('../assets/s97/cards/candy.jpg'),
						alt: 'Studio 97 - Candy Card',
					},
				])
			},
		},

		mounted() {
			window.scrollTo(0, 0)
		},
	}
</script>
