<template>
	<div>
		<Marquee
			:marquee_left="$props.marquee_left"
			:marquee_right="$props.marquee_right"
			:fullHeight="true"
		/>
	</div>
</template>

<script>
	import Marquee from '@/components/Marquee.vue'

	export default {
		name: 'Site Header',

		components: {
			Marquee,
		},

		props: {
			marquee_left: {
				type: Object,
				default: () => ({
					first: 'Ryan Gledhill Ryan Gledhill Ryan Gledhill Ryan Gledhill Ryan Gledhill',
				})
			},

			marquee_right: {
				type: Object,
				default: () => ({
					first: 'creative developer + designer &nbsp; / &nbsp; creative developer + designer &nbsp; / &nbsp; creative developer + designer &nbsp; / &nbsp; creative developer + designer &nbsp; / &nbsp; creative developer + designer &nbsp; / &nbsp; creative developer + designer &nbsp; / &nbsp; creative developer + designer &nbsp; / &nbsp; creative developer + designer &nbsp; / &nbsp; creative developer + designer &nbsp; / &nbsp; creative developer + designer &nbsp; / &nbsp; creative developer + designer &nbsp; / &nbsp; creative developer + designer &nbsp; / &nbsp; creative developer + designer &nbsp; / &nbsp; creative developer + designer /',
					right: true,
				})
			},
		},
	}
</script>
