<template>
	<div class="mb-20">
		<h1
			class="r-h1 mb-10 mx-5 2xl:px-10"
			v-text="$props.title"
		/>

		<div
			class="
				px-5
				md:flex md:justify-between
				2xl:px-20
			"
		>
			<p
				class="
					r-h2 w-full pb-8 max-w-screen-md
					md:w-1/2 md:pb-0 md:pr-20
					lg:pr-36
				"
				v-text="$props.subTitle"
			/>

			<div
				class="
					w-full border-black border-t
					md:w-1/2
				"
			>
				<Tag
					class="my-8"
					tag="Deliverables"
					dark="true"
				/>

				<ul
					class="md:ml-10 mb-2"
					v-for="item in $props.deliverables"
					:key="item"
				>
					<li
						v-text="item"
					/>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import Tag from '@/components/Tag.vue'

	export default {
		name: 'ProjectHeader',

		components: {
			Tag,
		},

		props: {
			title: {
				type: String,
				default: null,
			},

			subTitle: {
				type: String,
				default: null,
			},

			deliverables: {
				type: Array,
				default: () => ([]),
			},
		},
	}
</script>
