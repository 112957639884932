<template>
	<div>
		<SiteHeader
			class="lg:mb-10 z-10"
		/>

		<TagText
			tag="A quick intro."
			copy="Hey, I'm Ryan. A creative Developer + designer by day, and well... by night too. A keen music lover / producer, cyclist + all-round sports + fitness enthusiast from London."
			dark="true"
		/>

		<RButton
			:copy="$props.cta.copy"
			:src="$props.cta.src"
		/>

		<div class="mt-40 hidden md:block hr-black mx-5" />

		<TagText
			class="-mb-10"
			tag="What I do + tools."
			dark="true"
		/>

		<Skills />

		<Work
			id="projects"
			class="mt-20"
		/>

		<Contact/>

		<Fun />
	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import TagText from '@/components/TagText.vue'
import RButton from '@/components/RButton.vue'
import Skills from '@/components/Skills.vue'
import Work from '@/components/Work.vue'
import Contact from '@/components/Contact.vue'
import Fun from '@/components/Fun.vue'

export default {
	name: 'Home',
	components: {
		SiteHeader,
		TagText,
		RButton,
		Skills,
		Work,
		Contact,
		Fun,
	},
	
	props: {
		cta: {
			type: Object,
			default: () => ({
				copy: "Interested to know more?",
				src: "/about"
			}),
		},
	},


}
</script>
