<template>
	<div
		class="
			px-5
			2xl:px-20
		"
	>
		<div
			class="
				grid grid-cols-1 gap-5
				md:grid-cols-2
			"
		>
			<video
				class="w-full"
				v-for="item in $props.video"
				:key="item"
				controls
				muted
				playsinline
			>
				<source
					:src="item.src"
					type="video/mp4"
				>
			</video>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'VideoGrid',

		props: {
			video: {
				type: Array,
				required: true,
			},
		},
	}
</script>
