<template>
	<div>
		<ProjectHeader
			:title="$props.title"
			:subTitle="$props.subTitle"
			:deliverables="$props.deliverables"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull01.src"
			:alt="$props.imageFull01.alt"
		/>

		<ImageGrid
			:images="$props.imageGrid01"
		/>

		<ProjectCopy
			copy="SOCIAL MEDIA CONTENT + EVENT NIGHT DESIGNS."
		/>

		<Carousel
			:images="$props.carousel"
		/>

		<ProjectCopy
			copy="PRODUCING TAKEAWAY LABELS + COCKTAIL DESIGNS DURING THE COVID19 PANDEMIC."
		/>

		<ImageGrid
			:images="$props.imageGrid02"
		/>

		<ProjectCopy
			copy="more of the three's a crowd brand."
		/>

		<ImageGrid
			:images="$props.imageGrid03"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull02.src"
			:alt="$props.imageFull02.alt"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull03.src"
			:alt="$props.imageFull03.alt"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull04.src"
			:alt="$props.imageFull04.alt"
		/>

		<Contact />
	</div>
</template>

<script>
	import ProjectHeader from '@/components/ProjectHeader.vue'
	import ImageFull from '@/components/ImageFull.vue'
	import ImageGrid from '@/components/ImageGrid.vue'
	import ProjectCopy from '@/components/ProjectCopy.vue'
	import Carousel from '@/components/Carousel.vue'
	import Contact from '@/components/Contact.vue'

	export default {
		name: 'ThreesACrowd',

		components: {
			ProjectHeader,
			ImageFull,
			ImageGrid,
			ProjectCopy,
			Carousel,
			Contact,
		},

		props: {
			title: {
				type: String,
				default: 'Three’s A Crowd',
			},

			subTitle: {
				type: String,
				default: 'A modern gastro pub in the heart of harrogate.',
			},

			deliverables: {
				type: Array,
				default: () => ([
					'Web design + development',
					'Full-scale branding',
					'Social media content',
				])
			},

			imageFull01: {
				type: Object,
				default: () => ({
					src: require('../assets/tac/tac-front-logo.jpg'),
					alt: 'Three’s A Crowd storefront',
				})
			},

			imageFull02: {
				type: Object,
				default: () => ({
					src: require('../assets/tac/wine-01.jpg'),
					alt: 'Wine bottle',
				})
			},

			imageFull03: {
				type: Object,
				default: () => ({
					src: require('../assets/tac/wine-02.jpg'),
					alt: 'Wine bottle',
				})
			},

			imageFull04: {
				type: Object,
				default: () => ({
					src: require('../assets/tac/business-cards.jpg'),
					alt: 'Business cards',
				})
			},

			imageGrid01: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/tac/train-ticket.jpg'),
						alt: 'Train ticket cocktail garnish',
					},
					{
						src: require('../assets/tac/tac-front.jpg'),
						alt: 'Three’s A Crowd storefront',
					},
				])
			},

			imageGrid02: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/tac/cocktail-bags.jpg'),
						alt: 'Three’s A Crowd custom cocktails in a bag',
					},
					{
						src: require('../assets/tac/old-fashioned.jpg'),
						alt: 'Old Fashioned cocktail in a bottle',
					},
					{
						src: require('../assets/tac/pina.jpg'),
						alt: 'Piña Colada cocktail in a bottle',
					},
					{
						src: require('../assets/tac/espresso.jpg'),
						alt: 'Espresso Martini cocktail in a bottle',
					},
					{
						src: require('../assets/tac/paper-bag.jpg'),
						alt: 'Three’s A Crowd paper bag',
					},
					{
						src: require('../assets/tac/takeaway-boxes.jpg'),
						alt: 'Three’s A Crowd takeaway boxes',
					},
				])
			},

			carousel: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/tac/electro-brunch.jpg'),
						alt: 'Three’s A Crowd Social Panel',
					},
					{
						src: require('../assets/tac/electro-brunch-02.jpg'),
						alt: 'Three’s A Crowd Social Panel',
					},
					{
						src: require('../assets/tac/electro-brunch-03.jpg'),
						alt: 'Three’s A Crowd Social Panel',
					},
					{
						src: require('../assets/tac/sa-evening.jpg'),
						alt: 'Three’s A Crowd Social Panel',
					},
					{
						src: require('../assets/tac/truffle-night.jpg'),
						alt: 'Three’s A Crowd Social Panel',
					},
					{
						src: require('../assets/tac/gin.jpg'),
						alt: 'Three’s A Crowd Social Panel',
					},
					{
						src: require('../assets/tac/martini.jpg'),
						alt: 'Three’s A Crowd Social Panel',
					},
					{
						src: require('../assets/tac/whiskey.jpg'),
						alt: 'Three’s A Crowd Social Panel',
					},
					{
						src: require('../assets/tac/old-fashioned-panel.jpg'),
						alt: 'Three’s A Crowd Social Panel',
					},
					{
						src: require('../assets/tac/aperitivo.jpg'),
						alt: 'Three’s A Crowd Social Panel',
					},
				])
			},

			imageGrid03: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/tac/burger.jpg'),
						alt: 'Three’s A Crowd burger',
					},
					{
						src: require('../assets/tac/tac-snow.jpg'),
						alt: 'Three’s A Crowd snowy storefront',
					},
					{
						src: require('../assets/tac/no-11.jpg'),
						alt: 'Three’s A Crowd No.11',
					},
					{
						src: require('../assets/tac/bombay.jpg'),
						alt: 'Bombay Sapphire gin',
					},
				])
			},
		},

		mounted() {
			window.scrollTo(0, 0)
		},
	}
</script>
