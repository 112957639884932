<template>
  <div
    class="
			px-5 space-y-10
			md:pb-20 md:px-16 md:-ml-2
			lg:pb-40
			2xl:px-32 2xl:-ml-3
		"
  >
    <div
      class="
				w-full space-y-2
				md:mx-auto md:flex md:justify-between md:space-y-0 md:space-x-10
				group
			"
      v-for="item in $data.items"
      :key="item"
    >
      <h2
        class="
					text-2xl font-bold uppercase
					md:w-3/4 md:text-4xl
					lg:text-5xl
					xl:text-7xl

					md:opacity-25 md:group-hover:opacity-100 md:transition md:duration-500 md:ease-in-out
				"
        v-text="item.title"
      />

      <p
        class="
					text-xl
					md:w-3/4
					lg:text-2xl

					md:opacity-25 md:group-hover:opacity-100 md:transition md:duration-500 md:ease-in-out
				"
        v-text="item.copy"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "Development",
          copy:
            "Frontend / HTML / CSS / SCSS / JavaScript / VueJS / React / TypeScript / Tailwind / JQuery / GIT / GSAP",
        },
        {
          title: "Branding",
          copy:
            "Logo + Identity / Typography / Visual language / Guidelines / Mockups / Print",
        },
        {
          title: "Design",
          copy:
            "UI + UX design / Web design / Traditional graphic design / Motion design / Figma / Adobe CC",
        },
      ],
    };
  },
};
</script>
