<template>
	<div>
		<ProjectHeader
			:title="$props.title"
			:subTitle="$props.subTitle"
			:deliverables="$props.deliverables"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull01.src"
			:alt="$props.imageFull01.alt"
		/>

		<ImageGrid
			:images="$props.imageGrid01"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull02.src"
			:alt="$props.imageFull02.alt"
		/>

		<ProjectCopy
			copy="Pastel in the wild."
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull03.src"
			:alt="$props.imageFull03.alt"
		/>

		<ImageFull
			class="my-5"
			:src="$props.imageFull04.src"
			:alt="$props.imageFull04.alt"
		/>

		<ImageGrid
			class="my-5"
			:images="$props.imageGrid02"
		/>

		<Contact />
	</div>
</template>

<script>
	import ProjectHeader from '@/components/ProjectHeader.vue'
	import ImageFull from '@/components/ImageFull.vue'
	import ImageGrid from '@/components/ImageGrid.vue'
	import ProjectCopy from '@/components/ProjectCopy.vue'
	import Contact from '@/components/Contact.vue'

	export default {
		name: 'Pastel',

		components: {
			ProjectHeader,
			ImageFull,
			ImageGrid,
			ProjectCopy,
			Contact,
		},

		props: {
			title: {
				type: String,
				default: 'Pastel',
			},

			subTitle: {
				type: String,
				default: 'A hand-crafted + vibrant brand for a fun-filled cocktail disco club.',
			},

			deliverables: {
				type: Array,
				default: () => ([
					'Full-scale branding',
					'Hand-crafted logo design',
				])
			},

			imageFull01: {
				type: Object,
				default: () => ({
					src: require('../assets/pastel/pastel-pink.jpg'),
					alt: 'Pastel logo',
				})
			},

			imageFull02: {
				type: Object,
				default: () => ({
					src: require('../assets/pastel/pastel-gradients.jpeg'),
					alt: 'Pastel logo',
				})
			},

			imageFull03: {
				type: Object,
				default: () => ({
					src: require('../assets/pastel/pastel-posters.jpg'),
					alt: 'Pastel poster',
				})
			},

			imageFull04: {
				type: Object,
				default: () => ({
					src: require('../assets/pastel/pastel-poster-yellow.jpg'),
					alt: 'Pastel poster',
				})
			},

			imageGrid01: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/pastel/icon.gif'),
						alt: 'Pastel icon',
					},
					{
						src: require('../assets/pastel/logo.gif'),
						alt: 'Pastel logo',
					},
					{
						src: require('../assets/pastel/pastel-yellow.jpeg'),
						alt: 'Pastel icon',
					},
					{
						src: require('../assets/pastel/pastel-black.jpeg'),
						alt: 'Pastel logo',
					},
				])
			},

			imageGrid02: {
				type: Array,
				default: () => ([
					{
						src: require('../assets/pastel/pastel-stickers.jpg'),
						alt: 'Pastel stickers',
					},
					{
						src: require('../assets/pastel/pastel-poster-pink.jpg'),
						alt: 'Pastel poster',
					},
				])
			},
		},

		mounted() {
			window.scrollTo(0, 0)
		},
	}
</script>
