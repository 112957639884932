<template>
	<div
		class="
			px-5
			2xl:px-20
		"
	>
		<div
			class="
				grid grid-cols-1 gap-5
				md:grid-cols-2
			"
		>
			<img
				class="w-full"
				v-for="item in $props.images"
				:key="item"
				:src="item.src"
				:alt="item.alt"
			>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ImageGrid',

		props: {
			images: {
				type: Array,
				required: true,
			},
		},
	}
</script>
