<template>
	<div class="noise bg-black text-brand-white pt-20 cursor-pointer">
		<Marquee
			:marquee_left="$props.marquee_left"
		/>

		<a
			v-for="item in $data.items"
			:key="item"
			:href="item.url"
			class="
				border-b-4
				mx-5 px-2 py-10
				flex justify-between items-center space-x-5
				md:px-10
				lg:py-18
				2xl:px-10 2xl:py-20 2xl:mx-20
				item-hover
			"
		>
			<h2
				class="r-h2"
				v-text="item.title"
			/>

			<div>
				<ul
					v-for="type in item.type"
					:key="type"
					class="text-right"
				>
					<li
						class="
							leading-8 text-sm font-light text-brand-pink
							lg:leading-9
						"
						v-html="type"
					/>
				</ul>
			</div>
		</a>
	</div>
</template>

<script>
	import Marquee from '@/components/Marquee.vue'

	export default {
		name: 'Work',

		components: {
			Marquee,
		},

		props: {
			marquee_left: {
				type: Object,
				default: () => ({
					first: 'the<i>works </i>the<i>works </i>the<i>works </i>the<i>works </i>the<i>works </i>',
				})
			},
		},

		data() {
			return {
				items: [
					{
						title: 'Pose & Purpose',
						url: '/pose-and-purpose',
						type: [
							'Branding + Web design',
							'Web development',
						],
					},
					{
						title: 'Three’s A Crowd',
						url: '/threes-a-crowd',
						type: [
							'Branding',
							'Design',
						],
					},
					{
						title: 'The Music Awaits',
						url: '/the-music-awaits',
						type: [
							'Game development',
							'Branding + design',
						],
					},
					{
						title: 'Pastel',
						url: '/pastel',
						type: [
							'Branding',
							'Design',
						],
					},
					{
						title: 'Studio 97',
						url: '/studio97',
						type: [
							'Branding',
							'Visual identity',
						],
					},
					{
						title: 'logos + branding',
						url: '/logofolio',
						type: [
							'Branding',
							'Logo design',
						],
					},
					{
						title: 'Codepen',
						url: 'https://codepen.io/ryangled',
						type: [
							'Development playground',
							'Testing ground',
						],
					},
				],
			}
		},
	}
</script>

<style lang="scss" scoped>
	.item-hover {
		transition: all .7s cubic-bezier(.075, .82, .165, 1);

		h2 {
			transition: all .8s cubic-bezier(.075, .82, .165, 1);
			color: #D0D9CE;
		}
	}

	@media screen and (min-width: 500px) {
		.item-hover:hover {
			background-color: #D0D9CE;
			color: #101010;

			ul {
				li {
					color: #101010;
				}
			}

			h2 {
				color: #101010;
				margin-left: 1em;
			}
		}
	}
</style>
