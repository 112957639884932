<template>
	<div>
		<AboutHeader />

		<div
			class="
				px-5 mt-16 mb-16 lg:mb-40
				2xl:px-20
			"
		>
			<Tag
				class="mb-5"
				tag="An overview."
				dark="true"
			/>

			<p
				class="
					w-full max-w-xl md:pr-10 md:ml-10 lg:w-3/4
				"
				v-html="$props.copy"
			/>
		</div>

		<Timeline />
	</div>
</template>

<script>
	import AboutHeader from '@/components/AboutHeader.vue'
	import Tag from '@/components/Tag.vue'
	import Timeline from '@/components/Timeline.vue'

	export default {
		name: 'About',

		components: {
			AboutHeader,
			Tag,
			Timeline,
		},

		mounted() {
			window.scrollTo(0, 0)
		},

		props: {
			copy: {
				type: String,
				default: 'I’ve been developing my creative skills since 2016, both at university and as a full-time developer and freelancer in Leeds. After graduating from university with a First Class Degree in 2020, I focused my passion and decided to move away from the 3D and film scene and dive deeper into the world of design and development.<br><br> Now based in Shoreditch, London, I focus my time as a JavaScript Developer, building modern sites for a range of clients and businesses.<br><br> Away from the world of work, you’ll often find me out on the bike turning heads (away) in my lycra, lost on a mountain top, serving up at the tennis courts or jammin’ to some beats in the music studio. (But to be honest, most likely at the pub).',
			},
		},
	}
</script>
