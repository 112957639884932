<template>
	<div
		class="
			px-5
			2xl:px-20
		"
	>
		<video
			class="w-full"
			controls
			autoplay
			loop
			muted
			playsinline
		>
			<source
				:src="$props.video.src"
				type="video/mp4"
			>
		</video>
	</div>
</template>

<script>
	export default {
		name: 'VideoFull',

		props: {
			video: {
				type: Array,
				required: true,
			},
		},
	}
</script>
