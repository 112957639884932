import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Pastel from '../views/Pastel.vue'
import PoseAndPurpose from '../views/PoseAndPurpose.vue'
import ThreesACrowd from '../views/ThreesACrowd.vue'
import Studio97 from '../views/Studio97.vue'
import TheMusicAwaits from '../views/TheMusicAwaits.vue'
import Logofolio from '../views/Logofolio.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/about',
		name: 'About',
		component: About
	},
	{
		path: '/pastel',
		name: 'Pastel',
		component: Pastel
	},
	{
		path: '/pose-and-purpose',
		name: 'Pose & Purpose',
		component: PoseAndPurpose
	},
	{
		path: '/threes-a-crowd',
		name: 'Three’s A Crowd',
		component: ThreesACrowd
	},
	{
		path: '/studio97',
		name: 'Studio 97',
		component: Studio97
	},
	{
		path: '/the-music-awaits',
		name: 'The Music Awaits',
		component: TheMusicAwaits
	},
	{
		path: '/logofolio',
		name: 'Logofolio',
		component: Logofolio
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
