<template>
	<div
		class="
			px-5 py-10
			2xl:px-20
		"
	>
		<Tag
			class="mb-5"
			:tag="$props.tag"
			:dark="$props.dark"
		/>

		<h2
			v-text="$props.copy"
			:class="[
				'r-h2 w-full lg:w-3/4 max-w-screen-md md:pr-10 md:ml-10',
				$props.dark
					? 'text-brand-black'
					: 'text-brand-pink',
			]"
		/>
	</div>
</template>

<script>
	import Tag from '@/components/Tag.vue'

	export default {
		name: 'TagText',

		components: {
			Tag,
		},

		props: {
			tag: {
				type: String,
				default: null,
			},

			copy: {
				type: String,
				default: null,
			},

			dark: {
				type: String,
				default: null,
			},
		},
	}
</script>
