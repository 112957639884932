<template>
	<div class="relative z-20">
		<nav
			class="
				px-5 py-8
				md:py-10
			"
		>
			<div class="flex items-center justify-between">
				<a href="/">
					<span
						class="logo uppercase font-bold text-brand-black"
						v-text="$props.logo"
					/>
				</a>

				<div class="hr-black mx-5 w-full" />

				<div
					class="link-hover--black"
					@click="$data.open = !$data.open"
				>
					MENU
				</div>
			</div>
		</nav>

		<!-- Overlay -->
		<transition name="slide">
			<div
				v-if="$data.open"
				class="
					z-50 fixed top-0 left-0 right-0 bg-brand-black text-white overflow-hidden
				"
			>
				<nav
					class="
						px-5 py-8
						md:py-10
						absolute top-0 left-0 right-0
					"
				>
					<div class="flex items-center justify-between">
						<a href="/">
							<span
								class="uppercase font-bold text-brand-white"
								v-text="$props.logo"
							/>
						</a>

						<div class="hr-grey mx-5 w-full" />

						<div
							@click="$data.open = !$data.open"
							class="link-hover--white"
						>
							CLOSE
						</div>
					</div>
				</nav>

				<div class="flex items-center h-screen">
					<div>
						<a
							class="text-brand-white"
							v-for="item in $props.overlay"
							:key="item"
							:href="item.src"
						>
							<span
								@click="$data.open = !$data.open"
								class="
									text-5xl px-5 uppercase font-bold block mb-4
									md:text-8xl
									lg:text-9xl 
									hover:opacity-50 duration-300 ease-in-out cursor-pointer
								"
								v-text="item.title"
							/>
						</a>
					</div>

					<!-- Footer -->
					<div class="hidden md:block absolute bottom-0 left-0 right-0 z-50">
						<div class="hr-grey mx-5" />

						<div class="noise flex justify-between items-center bg-brand-white mt-10 px-5 py-8">
							<img
								class="animate animate-pulse"
								src="../assets/icons/circle-dark.svg"
							>

							<div class="flex space-x-4 md:space-x-16">
								<ul
									v-for="item in $props.social"
									:key="item"
								>
									<a
										:href="item.src"
									>
										<li
											class="link-hover--black text-brand-black hover:opacity-50 duration-300 ease-in-out"
											v-text="item.title"
										/>
									</a>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		props: {
			logo: {
				type: String,
				default: 'RYANGLEDHILL.'
			},

			nav: {
				type: Object,
				default: () => ({
					open: 'Menu',
					close: 'close',
				})
			},

			overlay: {
				type: Array,
				default: () => ([
					{
						title: 'About',
						src: '/about',
					},
					{
						title: 'Projects',
						src: '/#projects',
					},
				])
			},

			social: {
				type: Array,
				default: () => ([
					{
						title: 'LinkedIn',
						src: 'https://www.linkedin.com/in/ryan-gledhill-096763119/',
					},
					{
						title: 'Email',
						src: 'mailto:ryanjgledhill@gmail.com',
					},
					{
						title: 'The Gram',
						src: 'https://www.instagram.com/ry.desgn/',
					},
				])
			},
		},

		data() {
			return {
				open: false,
			}
		},
	};
</script>

<style lang="scss">
	.slide-enter-active {
		transition: all 1s cubic-bezier(.165,.84,.44,1) .1s;
	}

	.slide-leave-active {
		transition: all .6s cubic-bezier(.165,.84,.44,1) .1s;
	}
	
	.slide-enter-from, .slide-leave-to {
		transform: translateY(-100%);
	}
	.slide-enter-to, .slide-leave-from {
		transform: translateY(0);
	}

	.logo {
		transition: all 0.3s ease-out;

		&:hover {
			opacity: 0.5;
		}
	}
</style>
